import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { styled } from 'Theme/stitches.config';

import { VariantProvider } from 'Shared/Providers/VariantProvider';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from 'Shared/Hooks/useMedia';
import ProductAccordion from './ProductAccordion';
import { useRef } from 'react';
import SubscriptionAttributeArea from './SubscriptionAttributeArea';
import ProductCardImage from 'Commerce/Molecules/ProductCard/ProductCardImage';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import { scrollToElementByRef } from 'Shared/Common/Helpers';
import SubscriptionOfferProductPageModel from 'Models/Pages/ProductPage/SubscriptionOffer/SubscriptionOfferProductPageModel.interface';

function ProductPage() {
  const productData = useCurrentPage<SubscriptionOfferProductPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.bpMin961);
  const accordionElementRef = useRef<null | HTMLDivElement>(null);
  const { contentArea } = productData;

  const renderProductAccordion = () => {
    return (
      <ProductAccordionWrapper ref={accordionElementRef}>
        <ProductAccordion items={productData.accordions} />
      </ProductAccordionWrapper>
    );
  };

  return (
    <VariantProvider>
      <ContentContainer>
        <Grid>
          <ImageAndAccordion>
            <ProductCardImage
              image={productData.mainImage}
              premiumImage={productData.egmontPremiumMainImage}
              youSaveSticker={productData.youSaveSticker}
              imageScalingType={ImageScalingTypes.SUBSCRIPTION_OFFER_PAGE}
              discountTagCss={discountTagStyles}
            />
            {isDesktop && renderProductAccordion()}
          </ImageAndAccordion>
          <SubscriptionAttributeArea
            openFirstAccordionItem={() =>
              scrollToElementByRef(accordionElementRef)
            }
            productPage={productData}
            displayProductCTA={true}
          />
        </Grid>
        {!isDesktop && renderProductAccordion()}
      </ContentContainer>

      {contentArea && contentArea.length > 0 && (
        <ContentContainer>
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </ContentContainer>
      )}
    </VariantProvider>
  );
}

const Grid = styled('div', {
  g: 0,
  w: '100%',
  mb: '$s150',
  '@bpMin721': {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
    g: '$s200',
  },
});

const ProductAccordionWrapper = styled('div', {
  mb: 4,
  '@bpMin961': {
    mb: 12,
  },
});

const ImageAndAccordion = styled('div', {
  position: 'relative',
  px: 0,
  w: '100%',
  mb: '$s150',

  '@bpMin961': {
    mb: 0,
  },
});

const discountTagStyles = {
  t: 8,
  r: 8,
  fontSize: '$fontSize500',
  lineHeight: '1',
  fontFamily: 'fontSemiBold',
  px: 3,
  py: 2,
  '@bpMax720': {
    fontSize: '$fontSize100',
    t: 6,
    r: 6,
  },
};

export default ProductPage;
