import { styled } from 'Theme/stitches.config';
import React from 'react';

import loadableIcons from 'DesignSystem/Icons/LoadableIcons';
import KexIconLoader from 'DesignSystem/Icons/KexIconLoader';
import AccordionItem from '../Accordion/AccordionItem';

type PropTypes = {
  icon: keyof typeof loadableIcons;
  title: string;
  opened?: boolean;
  children?: React.ReactNode;
};

function IconBox({ icon, title, opened = false, children }: PropTypes) {
  const Icon = KexIconLoader(icon);

  return children ? (
    <AccordionWrapper>
      <AccordionItem
        header={
          <ExpandableHeader>
            <Icon
              size={'m'}
              color={'onInteractiveEmphasized'}
              ariaHidden={true}
            />
            <Text dangerouslySetInnerHTML={{ __html: title }}></Text>
          </ExpandableHeader>
        }
        opened={opened}
        hasCustomStyles
      >
        <Content>{children}</Content>
      </AccordionItem>
    </AccordionWrapper>
  ) : (
    <IconBoxWrapper>
      <Header>
        <Icon size={'m'} color={'onInteractiveEmphasized'} />
        <Text dangerouslySetInnerHTML={{ __html: title }}></Text>
      </Header>
    </IconBoxWrapper>
  );
}

const AccordionWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  border: '$borders$default solid $staticBorderDefault',
});

const IconBoxWrapper = styled(AccordionWrapper, {
  py: 2,
});

const commonHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 3,
  gap: '$s100',
};

const StyledHeader = {
  ...commonHeaderStyles,
  py: '9px',
};

const ExpandableHeader = styled('div', {
  ...commonHeaderStyles,
});

const Header = styled('div', {
  ...StyledHeader,
});

const Text = styled('div', {
  width: '100%',
  textAlign: 'left',
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
  '& em': {
    fontFamily: 'fontBold',
    fontStyle: 'normal',
  },
});

const Content = styled('div', {
  pl: 12,
  pr: 4,
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
});

export default IconBox;
